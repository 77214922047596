import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const space = process.env.REACT_APP_CONTENTFUL_SPACE;
const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
const environment = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || 'master';

const httpLink = new HttpLink({
  uri: `https://graphql.contentful.com/content/v1/spaces/${space}/environments/${environment}`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export default client;
