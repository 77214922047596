import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import NewsFeed from './NewsFeed';

const GET_PAGE_BY_SLUG = gql`
query GetPageBySlug($locale: String!, $slug: String!) {
  pageCollection(locale: $locale, where: {slug: $slug}, limit: 1) {
    items {
      title
      body {
        json
      }
    }
  }
}
`;

interface HomePageProps {
  locale: string;
}

const HomePage: React.FC<HomePageProps> = ({ locale }) => {
  const slug = 'home';
  
  const { loading, error, data } = useQuery(GET_PAGE_BY_SLUG, {
    variables: { locale, slug },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading home page content.</p>;
  if (!data.pageCollection.items.length) return <p>Home page content not found.</p>;

  const { title, body, featureImage } = data.pageCollection.items[0];

  return (
    <div>
      <h1>{title}</h1>
      {body && <div>{documentToReactComponents(body.json)}</div>}
      {featureImage && <img src={featureImage.url} alt="Feature" />}
      <NewsFeed locale={locale} />
    </div>
  );
};

export default HomePage;
