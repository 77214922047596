import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const GET_PAGE_DETAILS = gql`
query GetPageDetails($slug: String!, $locale: String!) {
  pageCollection(where: {slug: $slug}, locale: $locale, limit: 1) {
    items {
      title
      body {
        json
      }
    }
  }
}
`;

// Define your TypeScript interfaces here
interface PageDetailsProps {
  locale: string;
}

const PageDetails: React.FC<PageDetailsProps> = ({locale}) => {
  const { slug } = useParams<{ slug: string }>();
  const { loading, error, data } = useQuery(GET_PAGE_DETAILS, { variables: { slug, locale } });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  const page = data?.pageCollection.items[0];

  if (!page) return <p>No page found</p>;

  return (
    <div>
      <h1>{page.title}</h1>
      <div>{documentToReactComponents(page.body.json)}</div>
    </div>
  );
};

export default PageDetails;
