import React, {useState} from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LanguageSelector from './LanguageSelector';
import LogoImage from '../assets/mantsala_merkki-1024x813.png';

const GET_NAVIGATION_PAGES = gql`
query GetNavigationPages($locale: String!) {
  pageCollection(locale: $locale) {
    items {
      sys {
        id
      }
      slug
      title
    }
  }
}
`;

// Styled components
const StyledNav = styled.nav`
width: calc(100% + 40px); // Assuming 20px padding on each side of the parent
margin-left: -20px; // Move the nav to the edge of the screen
margin-right: -20px; // Move the nav to the edge of the screen
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: relative; // Needed for absolute positioning of children
`;
const NavItemsContainer = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  padding: 20px 0;
  border-top: 2px solid #007bff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  transform: translateY(-20px);

  @media screen and (min-width: 769px) {
    display: none; // Hide this container on larger screens
  }
`;

// Adjust the NavList for better alignment and spacing
const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px auto; // Center the items
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; // Adjust gap for vertical spacing between items

  @media (min-width: 769px) {
    flex-direction: row;
    align-items: center;
  }
`;
const DesktopNavList = styled(NavList)`
  display: none; // Start with hidden

  @media (min-width: 769px) {
    display: flex; // Make visible on larger screens
    flex-direction: row;
    position: relative; // Override if needed
  }
`;

const MobileIcon = styled.div`
  display: none;
  
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #495057;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover, &:focus {
    background-color: #007bff;
    color: #fff;
    outline: none;
  }
`;

const Logo = styled.div`
  width: 100px; // Adjust the width as needed
  height: 80px; // Adjust the height as needed
  background-image: url(${LogoImage});
  background-size: cover; // Or "contain" depending on your needs
  background-position: center;
`;
const StyledLanguageSelector = styled(LanguageSelector)`
  margin-left: auto;
`;
interface Page {
  sys: {
    id: string;
  };
  body: {
      json: any;
  };
  slug: string;
  title: string;
}

interface PageCollection {
  items: Page[];
}

interface QueryResult {
  pageCollection: PageCollection;
}
interface NavigationProps {
  currentLocale: string;
  onLocaleChange: (newLocale: string) => void;
}
interface BurgerIconProps {
  isOpen: boolean;
}

const BurgerIcon = styled.div<BurgerIconProps>`
  width: 2rem;
  height: 2px;
  background: #333;
  position: relative;
  transition: transform 0.3s ease;
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 2rem;
    height: 2px;
    background: #333;
    transition: transform 0.3s ease;
  }

  &::before {
    top: -6px;
  }

  &::after {
    top: 6px;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    background: transparent; // Hide the middle bar

    &::before {
      transform: rotate(45deg);
      top: 0;
    }

    &::after {
      transform: rotate(-45deg);
      top: 0;
    }
  `}
`;


const MobileIconContainer = styled.div`
  display: none;
  width: 40px; // Larger area for easier clicking or tapping
  height: 40px; // Larger area for easier clicking or tapping
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1100; // Ensure it's above other elements

  @media screen and (max-width: 768px) {
    display: flex;
  }
`;
// Navigation component adjustments
const Navigation: React.FC<NavigationProps> = ({ currentLocale, onLocaleChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { loading, error, data } = useQuery<QueryResult>(GET_NAVIGATION_PAGES, {
    variables: { locale: currentLocale },
  });

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  if (loading) return <p>Loading navigation...</p>;
  if (error) return <p>Error loading navigation.</p>;

  return (
    <StyledNav>
    <Link to="/">
      <Logo />
    </Link>
    <DesktopNavList>
      {data?.pageCollection.items.map(({ sys: { id }, slug, title }) => (
        <li key={id}>
          <StyledLink to={slug === 'home' ? '/' : `/${slug}`}>{title}</StyledLink>
        </li>
      ))}
      <li>
        <StyledLanguageSelector currentLocale={currentLocale} onLocaleChange={onLocaleChange} />
      </li>
    </DesktopNavList>
    <MobileIconContainer onClick={toggleNav}>
      <BurgerIcon isOpen={isOpen} />
    </MobileIconContainer>
    <NavItemsContainer isOpen={isOpen}>
      <NavList>
        {data?.pageCollection.items.map(({ sys: { id }, slug, title }) => (
          <li key={id}>
            <StyledLink to={slug === 'home' ? '/' : `/${slug}`} onClick={() => setIsOpen(false)}>{title}</StyledLink>
          </li>
        ))}
      </NavList>
      <StyledLanguageSelector currentLocale={currentLocale} onLocaleChange={onLocaleChange} />
    </NavItemsContainer>
  </StyledNav>
  );
};

export default Navigation;