import React from 'react';
import { gql, useQuery } from '@apollo/client';
import styled from 'styled-components';

const GET_NEWS_ARTICLES = gql`
query GetNewsArticles($locale: String!) {
    articleCollection(order: sys_publishedAt_ASC, locale: $locale) {
      items {
        title
        body
        publishDate
      }
    }
  }  
`;

const NewsArticle = styled.article`
  margin-bottom: 24px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const ArticleTitle = styled.h2`
  color: #333;
  font-size: 1.5rem;
`;

const ArticleBody = styled.p`
  color: #666;
`;

const PublishDate = styled.p`
  color: #999;
  font-size: 0.9rem;
  margin-top: -10px;
  margin-bottom: 10px;
`;

const NewsFeed: React.FC<{ locale: string }> = ({ locale }) => {
  const { loading, error, data } = useQuery(GET_NEWS_ARTICLES, {
    variables: { locale },
  });

  if (loading) return <p>Loading news...</p>;
  if (error) {
    console.error(error);
    return <p>Error fetching news.</p>;
  }

  // Function to format date
  const formatDate = (dateString: string, locale: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div>
      {data.articleCollection.items.map((article: any) => (
        <NewsArticle key={article.title}>
          <ArticleTitle>{article.title}</ArticleTitle>
          <PublishDate>{locale == 'fi' ? 'Julkaistu ' : 'Published on '}{formatDate(article.publishDate, locale)}</PublishDate>
          <ArticleBody>{article.body}</ArticleBody>
        </NewsArticle>
      ))}
    </div>
  );
};

export default NewsFeed;
