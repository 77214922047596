import React from 'react';
import styled from 'styled-components';

interface LanguageSelectorProps {
  currentLocale: string;
  onLocaleChange: (newLocale: string) => void;
}

const StyledSelect = styled.select`
  padding: 8px 16px;
  width: auto;
  min-width: 120px;
  border-radius: 5px;
  border: 1px solid #ced4da; /* Light gray border */
  background-color: #fff; /* White background */
  color: #495057; /* Dark gray text */
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  margin-left: 20px;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" fill="%23495057"><polygon points="0,0 1024,0 512,1024"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;

  &:hover {
    border-color: #80bdff; /* Lighter border on hover */
  }

  &:focus {
    border-color: #007bff; /* Blue border on focus */
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }
`;

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  currentLocale,
  onLocaleChange,
}) => (
  <StyledSelect
    value={currentLocale}
    onChange={(e) => onLocaleChange(e.target.value)}
  >
    <option value="en-US">{currentLocale == 'fi' ? 'Englanti' : 'English'}</option>
    <option value="fi">{currentLocale == 'fi' ? 'Suomi' : 'Finnish'}</option>
  </StyledSelect>
);

export default LanguageSelector;
