import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import HomePage from './components/HomePage';
import PageDetails from './components/PageDetails';
import './App.css';

function App() {
  const [locale, setLocale] = useState('en-US');

  return (
    <Router>
      <div className="App">
        <header>
          <Navigation currentLocale={locale} onLocaleChange={setLocale} />
        </header>
        <main>
          <Routes>
            <Route path="/" element={<HomePage locale={locale} />} />
            <Route path="/:slug" element={<PageDetails locale={locale} />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
